@import "video.js/dist/video-js.css";

html body {
  font-weight: 400;
  line-height: 1.5;
  font-family: "Arvo", serif;
}

main {
  margin: 11rem 1rem 4rem 16rem;
  max-width: 60rem;
}

body a {
  cursor: pointer;
}

button a {
  text-decoration: none;
}

a:any-link {
  text-decoration: underline;
}

.maroon,
.maroon a:any-link {
  color: #aa0c00;
}

.green,
.green a:any-link {
  color: #88bf10;
}

.teal,
.teal a:any-link {
  color: #00a6b7;
}

.orange,
.orange a:any-link {
  color: #ff8b00;
}

.white {
  color: white !important;
}

@media (max-width: 767px) {
  main {
    margin-top: 6rem;
    margin-left: 1rem;
    max-width: 100%;
  }
}

@media print {
  app-footer {
    display: none;
  }
}
/* Importing Bootstrap SCSS file. */
@import "node_modules/bootstrap/scss/bootstrap";
